import React, {
  FC,
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import moment from 'moment';
import { useInterval, usePrevious } from 'react-use';
import { IsoString, momentToIsoString, hasTodaySelected } from 'utils';
import {
  useGetStartTimesForSelectedDates,
  useSetEndTimeFromStartTimeForDesks,
  useSetStartTime,
  useSetEndTimeFromStartTimeForSpaces,
  useGetStartLocationTime,
  useTimezone,
} from 'atoms/resource';

export const ClockStateContext = createContext<IsoString>(
  momentToIsoString(moment())
);

export const ClockProvider: FC = ({ children }) => {
  const TICK_INTERVAL_IN_MS = 1000;

  const [clock, setClock] = useState<IsoString>(
    momentToIsoString(moment().startOf('minute'))
  );

  const { timezone } = useTimezone();
  const previousTimezone = usePrevious(timezone);

  const [isDocumentVisible, setIsDocumentVisible] = useState(!document.hidden);

  const selectedDates = useGetStartTimesForSelectedDates();

  const isViewingToday = useMemo(() => {
    if (!selectedDates) {
      return false;
    }

    return hasTodaySelected(selectedDates, timezone);
  }, [selectedDates, timezone]);

  const startTime = useGetStartLocationTime();

  const setStartTime = useSetStartTime();
  const setEndTimeFromStartTimeForSpaces =
    useSetEndTimeFromStartTimeForSpaces();
  const setEndTimeFromStartTimeForDesks = useSetEndTimeFromStartTimeForDesks();

  const handleVisibilityChange = () => {
    setIsDocumentVisible(!document.hidden);
  };

  /**
   * When the document is hidden i.e. robin app is not in focus, we don't
   * need to update the clock. Resume the clock when the app is in focus.
   */
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  //Only update on the minute mark
  useInterval(() => {
    if (isDocumentVisible) {
      const now = moment().startOf('minute');
      if (!now.isSame(moment(clock), 'minute')) {
        setClock(momentToIsoString(now));
      }
    }
  }, TICK_INTERVAL_IN_MS);

  // The clock is responsible for updating availability if it gets ahead of the start time
  useEffect(() => {
    const currentClock = moment(clock);

    if (
      !startTime ||
      (isViewingToday && moment(startTime).isBefore(currentClock))
    ) {
      setStartTime(currentClock);
      setEndTimeFromStartTimeForSpaces(currentClock);
      setEndTimeFromStartTimeForDesks(currentClock);
    }
  }, [
    clock,
    isViewingToday,
    startTime,
    setStartTime,
    setEndTimeFromStartTimeForSpaces,
    setEndTimeFromStartTimeForDesks,
  ]);

  useEffect(() => {
    if (!startTime) {
      return;
    }

    // This is a little hacky:
    // We detect timezone changes between renderes and then call the end time setters
    // The startTime does not actually change when the timezone changes,
    // But the setters internally will use the new timezone to calculate the end time
    if (previousTimezone && previousTimezone !== timezone) {
      setEndTimeFromStartTimeForSpaces(startTime);
      setEndTimeFromStartTimeForDesks(startTime);
    }
  }, [
    startTime,
    timezone,
    previousTimezone,
    setEndTimeFromStartTimeForDesks,
    setEndTimeFromStartTimeForSpaces,
  ]);

  return (
    <ClockStateContext.Provider value={clock}>
      {children}
    </ClockStateContext.Provider>
  );
};

export const useClock = (): IsoString => {
  const clock = useContext(ClockStateContext);
  return clock;
};
