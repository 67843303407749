import { useTranslation } from 'react-i18next';
import { Tag } from '@robinpowered/ui-kit';
import { DeskWithAvailability } from './types';
import styled from '@emotion/styled';

export const ZoneAvailability = ({
  zone,
}: {
  zone: { desks: DeskWithAvailability[] };
}) => {
  const { t } = useTranslation('deskDetails');

  return (
    <ZoneAvailabilityTag>
      {t('desk_list.available_desks', {
        available: zone.desks.filter((desk) => desk.isBookable).length,
        total: zone.desks.length,
      })}
    </ZoneAvailabilityTag>
  );
};

const ZoneAvailabilityTag = styled(Tag)`
  margin-left: var(--Components-Collapse-Global-marginSM, 12px);
`;
