import { useEffect, useState } from 'react';
import { useClock } from './useClock';
import {
  calculateMinimumEndTime,
  hasTodaySelected,
  LocationDateTimeMoment,
  momentToLocationDateTime,
} from 'utils/dateUtils';
import moment from 'moment';
import {
  useGetStartLocationTime,
  useGetStartTimesForSelectedDates,
  useTimezone,
} from 'atoms/resource';

type UseTimeFilters = {
  minStartTime: LocationDateTimeMoment | null;
  maxStartTime: LocationDateTimeMoment | null;
  minEndTime: LocationDateTimeMoment | null;
  maxEndTime: LocationDateTimeMoment | null;
};

export const useTimeFilters = (
  selectedLocation: string | null
): UseTimeFilters => {
  const { timezone } = useTimezone();
  const clock = useClock();

  const selectedDates = useGetStartTimesForSelectedDates();
  const startTime = useGetStartLocationTime();

  // State for start time filter
  const [times, setTimes] = useState<UseTimeFilters>({
    minStartTime: null,
    maxStartTime: null,
    minEndTime: null,
    maxEndTime: null,
  });

  function initializeTimeFiltersForSingleDay(
    dates: LocationDateTimeMoment[],
    selectedStartTime: LocationDateTimeMoment | null
  ) {
    // if start time is selected, end time can't be before
    const minEndTime = selectedStartTime
      ? dates[0].clone().set({
          hour: selectedStartTime.hour(),
          minute: selectedStartTime.minute(),
        })
      : dates[0].clone().startOf('day');

    if (hasTodaySelected(dates, timezone)) {
      // If today is selected, the end filters cannot go back in time
      setTimes({
        minStartTime: dates[0],
        maxStartTime: dates[0]
          .clone()
          .startOf('day')
          .add(1, 'day')
          .subtract(15, 'minutes'),
        minEndTime,
        maxEndTime: dates[0].clone().endOf('day').startOf('minute'),
      });
    } else {
      //Start time is all day
      setTimes({
        minStartTime: dates[0].clone().startOf('day'),
        maxStartTime: dates[0]
          .clone()
          .startOf('day')
          .add(1, 'day')
          .subtract(15, 'minutes'),
        minEndTime,
        maxEndTime: dates[0].clone().endOf('day').startOf('minute'),
      });
    }
  }

  // multi day allows any time to be selected
  // unless today is selected, then the start time can't be before the current time
  function initializeTimeFiltersForMultiDay(
    dates: LocationDateTimeMoment[],
    selectedStartTime: LocationDateTimeMoment | null
  ) {
    if (selectedDates) {
      const isTodaySelected = hasTodaySelected(selectedDates, timezone);

      if (selectedStartTime) {
        setTimes({
          minStartTime: isTodaySelected
            ? dates[0]
            : selectedStartTime.clone().startOf('day'),
          maxStartTime: selectedStartTime
            .clone()
            .startOf('day')
            .add(1, 'day')
            .subtract(15, 'minutes'),
          minEndTime: calculateMinimumEndTime(selectedStartTime),
          maxEndTime: selectedStartTime.clone().endOf('day').startOf('minute'),
        });
      }
    }
  }

  useEffect(() => {
    if (selectedDates) {
      const selectedMomentDate = selectedDates[0];

      const clockMoment = momentToLocationDateTime(moment(clock), timezone);

      // Set the clock clone to the selected date
      clockMoment.year(selectedMomentDate.year());
      clockMoment.month(selectedMomentDate.month());
      clockMoment.date(selectedMomentDate.date());

      if (selectedDates.length === 1) {
        initializeTimeFiltersForSingleDay([clockMoment], startTime);
      } else {
        initializeTimeFiltersForMultiDay([clockMoment], startTime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clock, selectedLocation, selectedDates, startTime, timezone]);

  return times;
};
