import { Button, Checkbox, message, Typography } from '@robinpowered/ui-kit';
import { FC, useCallback, useState } from 'react';
import { UserSearch } from '../graphql/useUserSearch';
import { UserSelectConnector } from './UserSelect/UserSelectConnector';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import {
  useDatePickerType,
  useGetDeskEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useSetDatePickerType,
  useSetSelectedDates,
  useTimezone,
} from 'atoms/resource';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { UserDetails } from '../UserDetails';
import { useReserveDelegatedDesk } from './hooks/graphql/useReserveDelegatedDesk';
import { BookingPolicyType, DeskReservationVisibility } from 'generated';
import { createRecurrenceFromDates } from 'utils';

type BookForProps = {
  deskId: string | undefined;
  setIsDelegateBooking: (value: boolean) => void;
  deskPolicyType: BookingPolicyType | undefined;
  isDuringExclusion: boolean;
};

export const BookFor: FC<BookForProps> = ({
  deskId,
  setIsDelegateBooking,
  deskPolicyType,
  isDuringExclusion,
}) => {
  const { t } = useTranslation('deskDetails');
  const { timezone } = useTimezone();
  const datePickerType = useDatePickerType();
  const startTimeMoments = useGetStartTimesForSelectedDates();
  const endTimeMoments = useGetDeskEndTimesForSelectedDates();

  const setDatePickerType = useSetDatePickerType();
  const setSelectedDates = useSetSelectedDates();

  const [sendEmail, setSendEmail] = useState(true);
  const [isPrivate, setIsPrivate] = useState(false);

  const [showDelegateBookingCard, setShowDelegateBookingCard] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserSearch | null>(null);

  const handleSendEmailChange = (event: CheckboxChangeEvent) => {
    setSendEmail(event.target.checked);
  };

  const handlePrivacyChange = (event: CheckboxChangeEvent) => {
    setIsPrivate(event.target.checked);
  };

  const { reserveDelegatedDesk, isReserving } = useReserveDelegatedDesk();

  const handleDelegateBooking = useCallback(async () => {
    if (
      !startTimeMoments ||
      !endTimeMoments ||
      !deskId ||
      !deskPolicyType ||
      !selectedUser
    ) {
      void message.open({
        type: 'error',
        content: t('desk_booking_controls.failure'),
      });

      return;
    }

    const hotBooking = deskPolicyType === BookingPolicyType.Hot;
    const bookingType = hotBooking
      ? BookingPolicyType.Hot
      : isDuringExclusion
      ? BookingPolicyType.ReverseHotel
      : BookingPolicyType.Hoteled;

    // User experience functionality here, if this was a multi-day booking,
    // we want to revert back to single day to show the booking card.
    // This is performed before the mutation to avoid screen flash.
    setSelectedDates([startTimeMoments?.[0]]);
    setDatePickerType('single');

    await reserveDelegatedDesk({
      variables: {
        recurrence:
          startTimeMoments && startTimeMoments?.length > 1
            ? createRecurrenceFromDates(startTimeMoments)
            : undefined,
        seatId: Number(deskId),
        email: selectedUser.primaryEmail?.email || '',
        type: bookingType,
        start: {
          dateTime: startTimeMoments[0].clone().utc().format(),
          timeZone: timezone,
        },
        end: {
          dateTime: endTimeMoments[0].clone().utc().format(),
          timeZone: timezone,
        },
        notify: sendEmail,
        visibility: isPrivate
          ? DeskReservationVisibility.JustMe
          : DeskReservationVisibility.Everyone,
      },
    });

    setIsDelegateBooking(false);
    setShowDelegateBookingCard(false);
  }, [
    startTimeMoments,
    endTimeMoments,
    deskId,
    deskPolicyType,
    selectedUser,
    isDuringExclusion,
    setSelectedDates,
    setDatePickerType,
    reserveDelegatedDesk,
    timezone,
    sendEmail,
    isPrivate,
    setIsDelegateBooking,
    t,
  ]);

  return (
    <>
      <DelegateBookingHeader>
        <Typography.Text>
          {t('desk_booking_controls.delegate_booking.book_for')}
        </Typography.Text>
        {!showDelegateBookingCard && (
          <Link
            onClick={() => {
              setIsDelegateBooking(false);
              setShowDelegateBookingCard(false);
            }}
          >
            {t('desk_booking_controls.delegate_booking.clear')}
          </Link>
        )}
      </DelegateBookingHeader>
      {!showDelegateBookingCard && (
        <UserSelectConnector
          placeholder={t(
            'desk_booking_controls.delegate_booking.name_placeholder'
          )}
          onSelectUser={(user) => {
            setShowDelegateBookingCard(true);
            setSelectedUser(user);
          }}
          errorText={t('desk_details.error')}
          formError={false}
          excludeCurrentUser={true}
        />
      )}
      {showDelegateBookingCard && (
        <DelegateReservationWrapper>
          <DelegateReservationContainer>
            <DelegateReservationSubContainer>
              <UserDetails
                avatarSrc={selectedUser?.avatar}
                name={selectedUser?.name}
                email={selectedUser?.primaryEmail?.email}
              />
              <Checkbox
                data-testid="send-email-checkbox"
                checked={sendEmail}
                onChange={handleSendEmailChange}
                disabled={datePickerType !== 'single'}
              >
                {t('desk_booking_controls.delegate_booking.send_email')}
              </Checkbox>
              <Checkbox
                data-testid="visibility-checkbox"
                checked={isPrivate}
                onChange={handlePrivacyChange}
                disabled={datePickerType !== 'single'}
              >
                {t('desk_booking_controls.mark_private')}
              </Checkbox>
              <BookingActions>
                <Button
                  type="primary"
                  style={{ marginRight: '8px' }}
                  onClick={handleDelegateBooking}
                  disabled={isReserving || !selectedUser}
                >
                  {t('desk_booking_controls.delegate_booking.book_action')}
                </Button>
                <Link
                  onClick={() => {
                    setIsDelegateBooking(false);
                    setShowDelegateBookingCard(false);
                  }}
                >
                  {t('desk_booking_controls.delegate_booking.cancel')}
                </Link>
              </BookingActions>
            </DelegateReservationSubContainer>
          </DelegateReservationContainer>
        </DelegateReservationWrapper>
      )}
    </>
  );
};

const DelegateBookingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const BookingActions = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
`;

const Link = styled.a`
  cursor: pointer;
  color: var(--Color-Text-link);
  &:hover {
    text-decoration: underline;
  }
`;

const DelegateReservationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;

  border-radius: var(--Border-Radius-borderRadius, 4px);
  border: 1px solid var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
`;

const DelegateReservationContainer = styled.div`
  display: flex;
  padding: var(--Space-Padding-paddingSM, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
`;

const DelegateReservationSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Padding-paddingSM, 12px);
  align-self: stretch;
`;
