import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import styled from '@emotion/styled';
import {
  Typography,
  Skeleton,
  Image,
  Button,
  Alert,
  theme,
} from '@robinpowered/ui-kit';
import { GroupSolid, HandicapAccessibleSolid } from '@robinpowered/icons';
import {
  ChevronLeftOutline,
  DeskHotSolid,
  LockSolid,
  SpaceSolid,
} from '@robinpowered/icons';
import {
  useCurrentlySelectedResource,
  useSetCurrentlySelectedResource,
  useGetStartTimesForSelectedDates,
  useGetSpaceEndTimesForSelectedDates,
} from '../../../atoms/resource';
import { useSpaceResourceDetails } from './graphql/useSpaceResourceDetails';
import { SpaceAmenities } from './SpaceAmenities';
import { SpaceBookingPolicies } from './SpaceBookingPolicies';
import { SpaceAvailabilityPills } from './space-availability-pills';
import { rightSidebarView } from 'atoms/sidebar/atoms';
import { SidebarContent, SidebarHeader } from 'components/global/sidebar';
import { SpaceAvailabilityTag } from './SpaceAvailabilityTag';
import { CreateSpaceRequestButton } from './CreateSpaceRequestButton';
import { useCurrentFilter, useSetCurrentFilter } from 'atoms/mapInteractions';
import { useSetSpaceSidebarView } from 'atoms/editSpace';
import { useBookingComposer } from './space-availability-pills/hooks';
import { AmplitudeEvents } from 'constants/amplitudeEvents';
import { useAmplitude } from 'contexts';

const { Title, Text } = Typography;

export const SpaceDetails = () => {
  const { t } = useTranslation('spaceDetails');
  const { type: selectedResourceType, id: selectedResourceId } =
    useCurrentlySelectedResource() || {};
  const [isRedirecting, setIsRedirecting] = useState(false);
  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const setRightSidebarView = useSetRecoilState(rightSidebarView);
  const setCurrentFilter = useSetCurrentFilter();
  const selectedStartTime = useGetStartTimesForSelectedDates();
  const selectedEndTime = useGetSpaceEndTimesForSelectedDates();
  const onClose = useCallback(() => {
    setCurrentFilter(null);
    setCurrentlySelectedResource(null);
    setRightSidebarView(undefined);
  }, [setCurrentlySelectedResource, setRightSidebarView, setCurrentFilter]);
  const setSpaceSidebarView = useSetSpaceSidebarView();
  const currentFilter = useCurrentFilter();
  const { trackEvent } = useAmplitude();
  const { linkToComposer } = useBookingComposer();
  const { token } = theme.useToken();
  const iconColor = token.colorIcon || '#6c6c6c';

  const { spaceDetails, locationDetails, loading } =
    useSpaceResourceDetails(selectedResourceId);

  const onBack = useCallback(() => {
    setCurrentlySelectedResource(null);
    setSpaceSidebarView('space-list');
  }, [setCurrentlySelectedResource, setSpaceSidebarView]);

  const handleNewEventClick = useCallback(() => {
    setIsRedirecting(true);
    trackEvent(AmplitudeEvents.SPACE_DETAILS_NEW_EVENT_CLICK);
    linkToComposer(
      spaceDetails?.id ?? '',
      selectedStartTime?.[0],
      selectedEndTime?.[0]
    );
  }, [
    trackEvent,
    linkToComposer,
    spaceDetails?.id,
    selectedStartTime,
    selectedEndTime,
  ]);

  const canBookSpace = useMemo(() => {
    if (!spaceDetails?.permissions) return false;
    return spaceDetails.permissions.some(
      (permission) => permission.name === 'spaces:book' && permission.value
    );
  }, [spaceDetails?.permissions]);

  if (loading) {
    return (
      <SidebarContent>
        <SpaceDetailsContent data-testid="skeleton">
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </SpaceDetailsContent>
      </SidebarContent>
    );
  }

  if (!spaceDetails || selectedResourceType !== 'spaces') {
    return null;
  }

  const isRequestOnly = spaceDetails?.isManaged;
  const isOnDemand =
    !spaceDetails?.calendar?.calendarId ||
    spaceDetails.calendar.calendarId === '';
  const allowAccessToComposer = canBookSpace && !isRequestOnly && !isOnDemand;

  const fitsText = `${t('space_details.fits')}: ${spaceDetails.capacity}`;
  const locationText = `${locationDetails?.levels?.[0]?.name}, ${locationDetails?.name}`;

  const openIssueReporting = () => {
    setRightSidebarView('report-space');
  };

  return (
    <>
      <SidebarHeader
        prefix={
          currentFilter === 'spaces' && (
            <BackButton type="link" onClick={onBack} data-testid="back-button">
              <ChevronLeftOutline size={16} />
            </BackButton>
          )
        }
        header={t('space_details.title')}
        onClose={onClose}
      />
      <SidebarContent>
        <SpaceDetailsContent>
          {spaceDetails.image && (
            <Image data-testid="space-image" src={spaceDetails.image} />
          )}
          {canBookSpace && (
            <SpaceAvailabilityTag selectedResourceId={selectedResourceId} />
          )}
          <SpaceTitle>
            <SpaceIcon>
              <SpaceSolid color={iconColor} />
            </SpaceIcon>
            <SpaceTitleWrapper>
              <Title data-testid="space-name" level={4} style={{ margin: 0 }}>
                {spaceDetails.name}
              </Title>
              <Text data-testid="space-location" type="secondary">
                {locationText}
              </Text>
            </SpaceTitleWrapper>
          </SpaceTitle>
          <SpaceSubDetails>
            {spaceDetails.capacity && (
              <AttributeContainer>
                <Group color={iconColor} />
                <Text>{fitsText}</Text>
              </AttributeContainer>
            )}
            {spaceDetails.isAccessible && (
              <AttributeContainer>
                <Handicap color={iconColor} />
                <Text>{t('space_details.accessible')}</Text>
              </AttributeContainer>
            )}
            {isRequestOnly && (
              <AttributeContainer>
                <LockSolidSmall color={iconColor} />
                <Text>{t('space_type.approval_required')}</Text>
              </AttributeContainer>
            )}
            {isOnDemand && (
              <AttributeContainer>
                <DeskHotSolidSmall color={iconColor} />
                <Text>{t('space_type.show_up_in_person')}</Text>
              </AttributeContainer>
            )}
          </SpaceSubDetails>
          {!canBookSpace && (
            <Alert
              message={t('space_type.no_permission')}
              type="info"
              showIcon
            />
          )}
          <Actions>
            {isRequestOnly && (
              <CreateSpaceRequestButton spaceId={selectedResourceId} />
            )}
            {allowAccessToComposer && (
              <Button
                onClick={handleNewEventClick}
                type="primary"
                disabled={isRedirecting}
                data-testid="new-event-button"
              >
                {t('buttons.new_event')}
              </Button>
            )}
            <Action
              onClick={openIssueReporting}
              type="default"
              disabled={isRedirecting}
            >
              {t('buttons.report_an_issue')}
            </Action>
          </Actions>
          {allowAccessToComposer && (
            <SpaceAvailabilityPills spaceId={spaceDetails.id} />
          )}
          <SpaceAmenities spaceId={spaceDetails.id} />
          <SpaceBookingPolicies spaceId={spaceDetails.id} />
        </SpaceDetailsContent>
      </SidebarContent>
    </>
  );
};

const SpaceDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--main-colors-white-white-0, #fff);
`;

const SpaceTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

const SpaceIcon = styled.div`
  display: flex;
  width: 44px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Color-Bg-Primary, #f7f6f6);
`;

const SpaceTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`;

const SpaceSubDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
  width: 300px;
`;

const Actions = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const Action = styled(Button)`
  display: flex;
  padding: 0px var(--Components-Button-Component-paddingInline, 15px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: var(--Components-Button-Global-borderRadius, 4px);
  border: var(--Components-Button-Global-lineWidth, 1px) solid
    var(--Components-Button-Component-defaultBorderColor, #d9d9d9);
  background: var(--Components-Button-Component-defaultBg, #fff);
`;

const Handicap = styled(HandicapAccessibleSolid)`
  width: 16px;
  height: 16px;
`;

const DeskHotSolidSmall = styled(DeskHotSolid)`
  width: 16px;
  height: 16px;
`;

const LockSolidSmall = styled(LockSolid)`
  width: 16px;
  height: 16px;
`;

const Group = styled(GroupSolid)`
  width: 16px;
  height: 16px;
`;

const AttributeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
`;

const BackButton = styled(Button)`
  padding: 0;
`;
