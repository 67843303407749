import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo } from 'react';
import { Button } from '@robinpowered/ui-kit';
import { Typography, Skeleton } from '@robinpowered/ui-kit';
import { useAuthContext, useMapControlsContext } from 'contexts';
import { useCanEditDesks, useDeskResourceDetails } from './graphql';
import {
  useCurrentlySelectedResource,
  useGetDeskEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useSetCurrentlySelectedResource,
} from '../../../atoms/resource';
import { DeskBookingPolicies } from './DeskBookingPolicies';
import moment from 'moment-timezone';
import { DeskAmenities } from './DeskAmenities';

import { DeskReservations } from './DeskReservations';
import { DeskTypeTag } from './DeskTypeTag';
import { BookingControls } from './booking';
import { Availability, DeskReservationVisibility, DeskType } from 'generated';
import { useTranslation } from 'react-i18next';
import { useBookingControlsContext } from 'contexts/BookingControlsContext';
import { useShareUrl } from 'hooks/useShareUrl';
import { useDeskReservationReservee } from './graphql/useDeskReservationReservee';
import { DeskInformation } from './DeskInformation';
import { DeskStickers } from './DeskStickers';
import {
  AssignedDeskAlert,
  DisabledDeskAlert,
  NoAccessOrPermissionAlert,
} from './alerts';
import { useBookingEnabledForDesk } from './hooks/useBookingEnabledForDesk';
import { SidebarContent, SidebarHeader } from 'components/global/sidebar';
import { useBackDestinationContext } from './edit-desk-reservation/contexts';
import { ChevronLeftOutline } from '@robinpowered/icons';
import {
  useSetDeskSidebarView,
  useSetRightSidebarView,
} from 'atoms/sidebar/hooks';
import { useSetCurrentFilter } from 'atoms/mapInteractions';
import { layoutToolUrl } from 'constants/url';
import { useLocationWorkingHours } from 'hooks/useLocationWorkingHours';
import { isLocationClosed } from 'utils/location';

const { Text } = Typography;

export const DeskDetails = () => {
  const { backDestination } = useBackDestinationContext();

  const selectedResource = useCurrentlySelectedResource();
  const { type: selectedResourceType, id: selectedResourceId } =
    selectedResource || {};
  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const setRightSidebarView = useSetRightSidebarView();
  const setDeskSidebarView = useSetDeskSidebarView();
  const setCurrentFilter = useSetCurrentFilter();

  const { t } = useTranslation('deskDetails');
  const { currentOrg, isAdmin, currentUser } = useAuthContext();
  const { selectedLevelId } = useMapControlsContext();
  const selectedStartTime = useGetStartTimesForSelectedDates();
  const selectedEndTime = useGetDeskEndTimesForSelectedDates();
  const { isBookingControlsSticky, setIsBookingControlsSticky } =
    useBookingControlsContext();

  const { location, loading: locationStateLoading } = useLocationWorkingHours();

  const orgSlug = useMemo(
    () => currentOrg?.slug || currentOrg?.id || '',
    [currentOrg]
  );

  const deskId = selectedResourceId;
  const {
    deskDetails,
    deskAvailability,
    loading: deskLoading,
  } = useDeskResourceDetails(deskId);

  const { canEditDesks, loading: canEditDesksLoading } = useCanEditDesks();

  const isAssignedAndCanAssignToOthers = useMemo(() => {
    return (
      deskDetails?.rawType?.includes(DeskType.Shared) &&
      deskDetails?.permissions?.some(
        (permission) =>
          permission.name === 'seats:assign' && permission.value === true
      ) &&
      deskDetails?.permissions?.some(
        (permission) =>
          permission.name === 'seats:delegate' && permission.value === true
      )
    );
  }, [deskDetails]);

  const durationInMinutes = useMemo(
    () =>
      selectedStartTime && selectedEndTime
        ? moment(selectedEndTime[0]).diff(
            moment(selectedStartTime[0]),
            'minutes'
          )
        : 480,
    [selectedStartTime, selectedEndTime]
  );

  const { reservations, loading: reservationsLoading } =
    useDeskReservationReservee(
      deskDetails?.id,
      durationInMinutes,
      selectedStartTime ? selectedStartTime[0] : undefined
    );

  const { copyButtonText, copyShareLink } = useShareUrl(reservations);
  const {
    isBookable,
    isSelectedStartTimeDuringExclusion,
    loading: bookingEnabledLoading,
  } = useBookingEnabledForDesk(deskId);

  const hideBookingControls = useMemo(() => {
    return !isBookingControlsSticky;
  }, [isBookingControlsSticky]);

  useEffect(() => {
    setIsBookingControlsSticky(null);
  }, [deskId, setIsBookingControlsSticky]);

  useEffect(() => {
    if (
      isBookingControlsSticky === null &&
      !deskLoading &&
      !bookingEnabledLoading &&
      !locationStateLoading &&
      deskAvailability
    ) {
      setIsBookingControlsSticky(
        isBookable &&
          !isLocationClosed(location?.state.availability.unavailableReasons)
      );
    }
  }, [
    deskAvailability,
    setIsBookingControlsSticky,
    isBookingControlsSticky,
    deskLoading,
    bookingEnabledLoading,
    isBookable,
    location?.state.availability.unavailableReasons,
    locationStateLoading,
  ]);

  const onBack = useCallback(() => {
    if (backDestination) {
      setDeskSidebarView(backDestination);
    }
  }, [setDeskSidebarView, backDestination]);

  const onClose = useCallback(() => {
    setCurrentlySelectedResource(null);
    setRightSidebarView(undefined);
    setCurrentFilter(null);
  }, [setCurrentlySelectedResource, setRightSidebarView, setCurrentFilter]);

  const openLayoutTool = useCallback(() => {
    if (orgSlug && selectedLevelId) {
      window.location.href = layoutToolUrl(orgSlug, selectedLevelId);
    }
  }, [orgSlug, selectedLevelId]);

  if (deskLoading) {
    return (
      <SidebarContent>
        <DeskDetailsContent data-testid="skeleton">
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </DeskDetailsContent>
      </SidebarContent>
    );
  }

  if (!deskDetails || !deskAvailability || selectedResourceType !== 'seats') {
    return null;
  }

  const openIssueReporting = () => {
    setRightSidebarView('report-desk');
  };

  const deskHasReservations = !!reservations?.length;
  const visibility = deskHasReservations && reservations[0].visibility;
  const reserveeId = deskHasReservations && reservations[0].reservee?.user?.id;

  const showCopyButton =
    !reservationsLoading &&
    deskHasReservations &&
    (visibility === DeskReservationVisibility.Everyone ||
      (visibility === DeskReservationVisibility.JustMe &&
        (reserveeId === currentUser?.id || isAdmin)));

  return (
    <>
      <SidebarHeader
        prefix={
          backDestination ? (
            <BackButton type="link" onClick={onBack} data-testid="back-button">
              <ChevronLeftOutline size={16} />
            </BackButton>
          ) : undefined
        }
        header={t('desk_details.title')}
        onClose={onClose}
      />
      <SidebarContent>
        <DeskDetailsContent>
          <DeskInfoHeader>
            <DeskTypeTag deskTypes={deskDetails.rawType} />

            <DeskInformation
              name={deskDetails.name}
              levelName={deskDetails.level?.name}
              locationName={deskDetails.location.name}
              zoneName={deskDetails.zone?.name}
            />
          </DeskInfoHeader>

          <NoAccessOrPermissionAlert
            unbookableReasons={deskAvailability?.unbookableReasons}
          />

          {!hideBookingControls && (
            <BookingControls
              conflictingReservations={deskDetails.state.reservations}
              unbookableReasons={deskAvailability.unbookableReasons}
              bookingEnabled={
                deskAvailability.availability === Availability.Available &&
                !isLocationClosed(
                  location?.state.availability.unavailableReasons
                )
              }
              isDuringExclusion={isSelectedStartTimeDuringExclusion}
            />
          )}
          <DeskReservations deskId={deskId}></DeskReservations>

          <DisabledDeskAlert
            isReservable={deskDetails.isReservable}
            isDisabled={deskDetails.isDisabled}
          />

          <AssignedDeskAlert
            isDuringExclusion={isSelectedStartTimeDuringExclusion}
            isReservable={deskDetails.isReservable}
            isDisabled={deskDetails.isDisabled}
            rawTypes={deskDetails.rawType}
            availability={deskAvailability.availability}
          />

          <DeskAmenities deskId={deskId}></DeskAmenities>
          <DeskBookingPolicies deskId={deskId}></DeskBookingPolicies>
          <DeskStickers stickers={deskDetails.stickers} />

          <CallToActions>
            <Actions>
              {isAssignedAndCanAssignToOthers && (
                <Action>
                  <ActionContent
                    onClick={() => setDeskSidebarView('edit-desk-assignments')}
                  >
                    {t('buttons.manage_assignment')}
                  </ActionContent>
                </Action>
              )}

              {!canEditDesksLoading && canEditDesks && (
                <Action data-testid="edit-desk-button" onClick={openLayoutTool}>
                  {t('buttons.edit_desk')}
                </Action>
              )}
            </Actions>
            <Actions>
              {showCopyButton && (
                <Action onClick={copyShareLink}>{copyButtonText}</Action>
              )}
              <Action onClick={openIssueReporting}>
                {t('buttons.report_an_issue')}
              </Action>
            </Actions>
          </CallToActions>
        </DeskDetailsContent>
      </SidebarContent>
    </>
  );
};

const DeskDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--main-colors-white-white-0, #fff);
`;

const DeskInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const CallToActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const Actions = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const Action = styled(Button)`
  display: flex;
  padding: 0px var(--Components-Button-Component-paddingInline, 15px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: var(--Components-Button-Global-borderRadius, 4px);
  border: var(--Components-Button-Global-lineWidth, 1px) solid
    var(--Components-Button-Component-defaultBorderColor, #d9d9d9);
  background: var(--Components-Button-Component-defaultBg, #fff);
`;

const ActionContent = styled(Text)`
  && {
    margin: 0;
  }
`;

const BackButton = styled(Button)`
  padding: 0;
`;
