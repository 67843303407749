import {
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from 'components/global/sidebar';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@robinpowered/ui-kit';
import { DeskDetailsInfoOnly } from '../DeskDetailsInfoOnly';
import {
  useEditDeskNewDeskId,
  useEditDeskPotentialDeskId,
  useSetEditDeskNewDeskId,
  useSetEditDeskPotentialDeskId,
} from 'atoms/editDesk';
import ChevronLeftOutline from '@robinpowered/icons/ChevronLeftOutline';
import styled from '@emotion/styled';
import { useCurrentlySelectedResource } from 'atoms/resource';
import { useBookingEnabledForDesk } from '../hooks/useBookingEnabledForDesk';
import { useBackDestinationContext } from './contexts';
import { useDeskSidebarView, useSetDeskSidebarView } from 'atoms/sidebar/hooks';

export const EditDeskDetailsView: FC = () => {
  const { t } = useTranslation('deskDetails');
  const view = useDeskSidebarView();
  const setView = useSetDeskSidebarView();
  const { backDestination } = useBackDestinationContext();

  const currentDeskId = useCurrentlySelectedResource()?.id;
  const newDeskId = useEditDeskNewDeskId();
  const potentialDeskId = useEditDeskPotentialDeskId();

  const setNewDeskId = useSetEditDeskNewDeskId();
  const setPotentialDeskId = useSetEditDeskPotentialDeskId();

  const { isBookable, loading } = useBookingEnabledForDesk(potentialDeskId);

  const deskId = useMemo(() => {
    switch (view) {
      case 'new-desk':
        return newDeskId;
      case 'potential-desk':
        return potentialDeskId;
      case 'current-desk':
        return currentDeskId;
      default:
        return null;
    }
  }, [currentDeskId, newDeskId, potentialDeskId, view]);

  const onClose = useCallback(() => {
    if (backDestination) {
      setView(backDestination);
    }
    setPotentialDeskId(null);
  }, [backDestination, setView, setPotentialDeskId]);

  const commitPotentialDesk = useCallback(() => {
    setNewDeskId(potentialDeskId);
    setPotentialDeskId(null);
    setView('edit-summary');
  }, [potentialDeskId, setNewDeskId, setPotentialDeskId, setView]);

  return (
    <>
      <SidebarHeader
        prefix={
          <BackButton type="link" onClick={onClose} data-testid="back-button">
            <ChevronLeftOutline size={16} />
          </BackButton>
        }
        header={t('desk_details_title')}
        onClose={onClose}
      />

      <SidebarContent>
        <DeskDetailsInfoOnly deskId={deskId} />
      </SidebarContent>

      {view !== 'potential-desk' && (
        <SidebarFooter
          data-testid="back-to-editing-footer"
          actions={{
            secondary: {
              label: t('edit_desk_reservation.footer.back_to_editing'),
              onClick: onClose,
              disabled: false,
            },
          }}
        />
      )}
      {view === 'potential-desk' && (
        <SidebarFooter
          data-testid="change-desk-footer"
          actions={{
            primary: {
              label: t('edit_desk_reservation.footer.change_desk'),
              onClick: commitPotentialDesk,
              disabled: !isBookable || loading,
            },
          }}
        />
      )}
    </>
  );
};

const BackButton = styled(Button)`
  padding: 0;
`;
